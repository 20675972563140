import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const GetStart = () => {
    return (
        <>
            <div className='container-fluid mx-auto py-32'>
                <div className='flex sm:flex-row flex-col relative h-full items-center'>
                    <div className='flex sm:basis-1/2 basis-full'>
                        <div className='absolute left-0'>
                        {/* leftOval.svg */}
                            {/* <picture><img alt="left" src='/image/new/elipseHeader@1x.jpg' /></picture> */}
                            <div className="test-light"></div>
                        </div>
                        <div className='flex flex-col relative justify-center items-center px-5 lg:px-14 xl:px-16'>
                            <ScrollAnimation initiallyVisible={true} animateOnce={true} animateIn="animate__fadeInLeft">
                                <div className='font-semibold xl:text-[3.75rem] text-[1.8rem] text-white lg:text-[2rem] xl:leading-[4.875rem]'>
                                    Every Payment method in <br /> <span className='text-transparent bg-clip-text bg-gradient-to-r from-[#23F4F9] to-[#23F4F9]'> one Platform</span>
                                </div>
                                <div className='pt-14 text-[1rem] text-white'>
                                    The future is contactless payments and commerce-on-the-go – and the future is now!
                                </div>

                                <div className='flex flex-row mt-12'>
                                    <a href='/' type="button" className='items-center flex mr-5 bg-white hover:bg-[#23F4F9] transition-colors text-txtColor text-center rounded px-6 py-3 text-[1rem]'>Get Started</a>
                                    <a href='/' type="button" className='flex text-center rounded px-6 py-3 text-[1rem] cta-btn btn-p'>Purchace</a>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className='sm:flex sm:basis-1/2 basis-full hidden items-center justify-end'>


                        <div className='absolute right-0 top-[-8rem]'>
                        {/* halloRight.svg */}
                            {/* <img alt='right' src="/image/new/elipsesHeader@1x.jpg" /> */}
                        </div>
                        <div className="gradiant-right"></div>
                        <div className="gradiant-right-top"></div>
                        
                        {/* <div className='absolute right-12 top-[-8rem]'>
                            <img alt='right' src="/image/Ellipse53.svg" />
                        </div> */}
                        <img alt='Hero bxbinfc' className='xl:w-full sm:w-[25rem] lg:w-[30rem] relative' src="/image/hero.png" />
                    </div>

                </div>
            </div>
        </>
    )
}

export default GetStart;