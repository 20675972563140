import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const Feature = () => {
    return (
        <>
            <div className='relative'>
                <div className='lg:block hidden'>
                    <picture><img alt="dollarCircle" className='sm:w-[12.5rem] w-auto lg:w-auto right-0 absolute' style={{ top: 'calc(50% - 60px)' }} src='/image/dollarCircle.svg' /></picture>
                </div>
                <div className='container mx-auto mt-0 mb-0 pt-8 mb-24'>
                    <div className='flex flex-row justify-center lg:text-[3.75rem] sm:text-[2rem] text-[1.5rem]'>
                        <div className='text-white'>
                            <ScrollAnimation initiallyVisible={true} animateOnce={true} animateIn="animate__fadeIn">
                                Features
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className='flex sm:flex-row flex-col md:px-20 lg:px-44 xl:px-44 2xl:px-72 text-white items-start features-sec pro mt-0 md:mt-16'>
                        <div className='flex flex-col basis-1/2'>

                            <div className='flex flex-col sm:justify-start sm:text-start sm:px-0 px-10 sm:pl-10'>
                                <ScrollAnimation animateOnce={true} animateIn="animate__fadeInLeft">
                                    <div>
                                        <img alt='wallet' className='sm:w-auto w-[3rem]' src="/image/proce.png" />
                                    </div>
                                    <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                        High Performance
                                    </div>
                                    <div className='sm:pt-5 pt-2 sm:pr-3'>
                                        Quad-Core Processor
                                        <br />
                                        Run multiple Apps and process data efficiently.
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <img alt="pic" src="/image/line.svg" className='my-10 sm:block hidden' style={{ filter: 'grayscale(1)' }} />
                            <div className='flex flex-col sm:justify-start sm:text-start sm:px-0 px-10 lg:mt-2 mt-10 sm:pl-10'>
                                <ScrollAnimation animateOnce={true} delay={600} animateIn="animate__fadeInLeft">
                                    <div>
                                        <img alt='confidentiality' className='sm:w-auto w-[3rem]' src="/image/wifi.png" />
                                    </div>
                                    <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                        Dual-Band Wi-Fi
                                    </div>
                                    <div className='sm:pt-5 pt-2 sm:pr-3'>
                                        Enjoy a smoother operation enabled by faster transmission and less interference.
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className='flex flex-col basis-1/2 justify-center'>
                            <div className='flex flex-col sm:justify-start sm:text-start sm:px-0 px-10 lg:mt-[3rem] sm:mt-40 sm:pl-10'>
                                <ScrollAnimation animateOnce={true} delay={300} animateIn="animate__fadeInRight">
                                    <div>
                                        <img alt='multiplePaymentMethods' className='sm:w-auto w-[3rem] md:pt-10 pt-20' src="/image/sim.png" />
                                    </div>
                                    <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                        vSIM
                                    </div>
                                    <div className='sm:pt-5 pt-2 sm:pr-3'>
                                        Enjoy what a SIM card can offer you, but WITHOUT installing a physical card.
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <img alt='line' src="/image/line.svg" className='pt-10 sm:block hidden' style={{ filter: 'grayscale(1)' }}  />
                            <div className='flex flex-col sm:justify-start sm:text-star sm:px-0 px-10 lg:mt-2 mt-10 sm:pl-10'>
                                <ScrollAnimation animateOnce={true} delay={900} animateIn="animate__fadeInRight">
                                    <div>
                                        <img alt='competitivePrice' className='sm:w-auto w-[3rem] pt-10' src="/image/4g.png" />
                                    </div>
                                    <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                        4G Connectivity
                                    </div>
                                    <div className='sm:pt-5 pt-2 sm:pr-3'>
                                        Stay connected at anytime and anywhere.
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Feature;