import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const Cards = () => {
    return (
        <>
            <div className='container mx-auto mt-0 mb-0 relative overflow-hidden'>
                <picture>
                    <img className='absolute top-[-200px] hidden lg:block' style={{ left: 'calc(50% - 650px)' }} src="/image/shadow-cards.png" alt="" srcset="" />
                </picture>
                <div className='lg:text-[3.75rem] sm:text-[2rem] text-[1.5rem] mt-24 mb-16 relative hidden md:block'>
                    <div className='text-white text-center'>
                        <ScrollAnimation initiallyVisible={true} animateOnce={true} animateIn="animate__fadeIn">
                            Cards
                        </ScrollAnimation>
                    </div>
                </div>
                <div className='flex sm:flex-row flex-col-reverse mt-20'>
                    <div className='flex grow sm:basis-1/2 basis-full items-center'>
                        <div>
                            <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInLeft">
                                <picture><img alt='nfc Card' style={{margin:'0 auto'}} className='w-[500px]' src='/image/paymentMethod1NFCa.png' /></picture>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className='flex flex-col grow basis-1/2 justify-center items-center px-5 lg:px-10'>
                        <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInRight">
                            <div className='lg:text-[3rem] text-[1.5rem] md:text-start text-center'>
                                <span className='text-[#23F4F9]'>NFC Cards</span>
                            </div>
                            <div className='flex py-5 text-white font-extralight lg:font-normal text-left md:text-justify'>
                                AIO NFC cards communicate with NFC POS devices. The NFC card and a card reader will engage with each other to complete the payment lightning-fast. NFC cards are secure and easy to use.
                            </div>
                            <div className='flex flex-row pt-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    NFC cards are one of the fastest & easiest payment methods.
                                </div>
                            </div>
                            <div className='flex flex-row py-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    NFC cards are highly secured & encrypted and reduce the risks.
                                </div>
                            </div>
                            <div className='flex flex-row text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    The contactless tech in NFC cards keeps your information safe.
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards