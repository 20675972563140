import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const Features = () => {
    return (
        <>
            <div className='container mx-auto mt-0 mb-0 py-8' id='features'>
                <div className='flex flex-row justify-center lg:text-[3.75rem] sm:text-[2rem] text-[1.5rem]'>
                    <div className='text-white'>
                        <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeIn">
                            Features
                        </ScrollAnimation>
                    </div>
                </div>
                <div className='flex sm:flex-row flex-col md:px-20 lg:px-44 xl:px-44 2xl:px-72 text-white items-start features-sec mt-0 md:mt-16'>
                    <div className='flex flex-col basis-1/2'>

                        <div className='flex flex-col sm:justify-start sm:text-start sm:px-0 px-10 lg:mt-20 mt-10 sm:pl-10'>
                            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInLeft">
                                <div>
                                    <img alt='wallet' className='sm:w-auto w-[3rem]' src="/image/wallet.svg" />
                                </div>
                                <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                    Digital Wallet
                                </div>
                                <div className='sm:pt-5 pt-2 sm:pr-5'>
                                    A crypto wallet for AIO transactions with the capability of supporting more tokens in the future.
                                </div>
                            </ScrollAnimation>
                        </div>
                        <img src="/image/line.svg" className='my-10 sm:block hidden' />
                        <div className='flex flex-col sm:justify-start sm:text-start sm:px-0 px-10 lg:mt-2 mt-10 sm:pl-10'>
                            <ScrollAnimation animateOnce={true} delay={600} animateIn="animate__fadeInLeft">
                                <div>
                                    <img alt='confidentiality' className='sm:w-auto w-[3rem] pt-10' src="/image/confidentiality.svg" />
                                </div>
                                <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                    Confidentiality
                                </div>
                                <div className='sm:pt-5 pt-2 sm:pr-5'>
                                    Guarantee customer's privacy via highest various security layers.
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className='flex flex-col basis-1/2 justify-center'>
                        <div className='flex flex-col sm:justify-start sm:text-start sm:px-0 px-10 lg:mt-[13rem] sm:mt-40 sm:pl-10'>
                            <ScrollAnimation animateOnce={true} delay={300} animateIn="animate__fadeInRight">
                                <div>
                                    <img alt='multiplePaymentMethods' className='sm:w-auto w-[3rem] md:pt-10 pt-20' src="/image/multiplePaymentMethods.svg" /> 
                                </div>
                                <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                    Multiple Payment Methods
                                </div>
                                <div className='sm:pt-5 pt-2 sm:pr-5'>
                                    Payment via both phone and NFC card
                                </div>
                            </ScrollAnimation>
                        </div>
                        <img alt='line' src="/image/line.svg" className='pt-10 sm:block hidden' />
                        <div className='flex flex-col sm:justify-start sm:text-star sm:px-0 px-10 lg:mt-2 mt-10 sm:pl-10'>
                            <ScrollAnimation animateOnce={true} delay={900} animateIn="animate__fadeInRight">
                                <div>
                                    <img alt='competitivePrice' className='sm:w-auto w-[3rem] pt-10' src="/image/competitivePrice.svg" />
                                </div>
                                <div className='lg:text-[2.125rem] text-[1.3rem] sm:pt-5 pt-2'>
                                    Competitive Price
                                </div>
                                <div className='sm:pt-5 pt-2 sm:pr-5'>
                                    Reasonable price for all services and products, low transaction fees.
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features;