import Nav from './component/Nav';
import Cards from './component/Products/Cards';
import Feature from './component/Products/Feature';
import { Information } from './component/Products/Information';
import Introduction from './component/Products/Introduction';
import Start from './component/Products/Start';
import Place from './component/Products/Place';
import Footer from './component/Footer';
import { useEffect } from 'react';

export function Product() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[])
    return (
        <>
            <Nav />
            <Start />
            <Feature />
            <Information />
            <Cards />
            <Introduction />
            <Place />
            <Footer />
        </>
    )
}