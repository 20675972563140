import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const NfcCardMethod = () => {
    return (
        <>
            <div className='container mx-auto mt-16'>
                <div className='flex sm:flex-row flex-col-reverse'>
                    <div className='flex grow sm:basis-1/2 basis-full items-center'>
                        <div>
                            <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInLeft">
                                {/* /image/nfcCard.svg */}
                                <picture><img alt='nfc Card' className='w-[500px]' src='/image/new/paymentMethod1NFC.png' /></picture>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className='flex flex-col grow basis-1/2 items-center justify-center px-5 lg:px-10'>
                        <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInRight">
                            <div className='lg:text-[3rem] text-[1.5rem] sm:text-start text-center'>
                                <span className='text-[#23F4F9]'>NFC Card</span><span className='text-white'> Method</span>
                            </div>
                            <div className='flex py-5 text-white font-extralight lg:font-normal text-left md:text-justify'>
                                AIO NFC Card allows users to benefit from a lightning-fast payment method easily. This Contactless payment system sends the payment information to a secure point-of-sale reader.                </div>
                            <div className='flex flex-row pt-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    One of the most secure and convenient payment methods ever
                                </div>
                            </div>
                            <div className='flex flex-row py-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    Close your card and pay to make purchases at merchants that supports AIO NFC terminal.
                                </div>
                            </div>
                            <div className='flex flex-row text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    The whole process of NFC card payment is easy to use and understand.
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NfcCardMethod