import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="bg-bgFooter z-100 lg:px-24 px-5 mx-auto mt-0 mb-0">
                <div className='container-fluid'>
                    <div className="flex sm:flex-row flex-col pt-11 mb-10">
                        <div className="flex lg:basis-1/2 xl:basis-7/12 basis-full">
                            <div className="xl:text-[2.125rem] sm:text-[1.5rem] text-[1.3rem] text-white text-center md:text-start">
                                Subscribe to stay tuned
                                <br className="lg:block hidden" />for special offers!
                            </div>
                        </div>
                        <div className="flex lg:basis-1/2 xl:basis-5/12 basis-full items-center mt-6 md:mt-0">
                            <div className="bg-inputColor w-full flex items-center justify-between py-4 pl-6 pr-4 rounded">
                                <img logo="logo" src="/image/messag.svg" />
                                <div className="grow px-3">
                                    <input type="email" style={{ background: 'none' }} className="border-0 outline-0 text-gray w-full" placeholder="Your Email Address" />
                                </div>
                                <button type="submit" className="text-txtColor hover:bg-[#23F4F9] transition-colors bg-white focus:ring-4 rounded text-sm px-5 py-3">Subscribe!</button>
                            </div>
                        </div>
                    </div>

                    <div className="flex sm:flex-row flex-col text-white">

                        <div className="basis-full xl:basis-7/12 lg:basis-1/2 sm:basis-5/12 grow">

                            <div className="sm:px-0 px-2 mt-5 lg:mt-0">
                                <ul>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        {/* <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[1rem]"> */}
                                        <NavLink className="" to="/">
                                            <img className='w-40 sm:w-48' src='image/aio-nfc-logo.png' alt='logo' />
                                        </NavLink>
                                    </li>
                                    <li className="pt-5 flex justify-center sm:justify-start  text-[1rem]">
                                        <div className="text-white">
                                            Every Payment  method in
                                            <br />
                                            <span className="text-[#23F4F9]">
                                                one Platform
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex sm:flex-row flex-col basis-full lg:basis-1/2 sm:basis-7/12 xl:basis-5/12 grow">
                            <div className="basis-full xl:basis-1/3 mt-5 lg:mt-0">

                                <div className="mb-6 text-[0.85rem] text-white flex justify-center sm:justify-start">Payment Methods</div>
                                <ul>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">NFC Card</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">NFC on Phone</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">QR-Code</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">Address wallet</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="basis-full xl:basis-1/3 mt-5 lg:mt-0">

                                <div className="mb-6 text-[0.85rem] text-white flex justify-center sm:justify-start">Features</div>
                                <ul>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">Digital Wallet</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">Currency Exchange</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">NFC Support</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="#" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">Bills & Receipts</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="basis-full xl:basis-1/3 mt-5 lg:mt-0">

                                <h3 className="mb-6 text-[0.85rem] text-white flex justify-center sm:justify-start">Contact Us</h3>
                                <ul>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="mailto:info@bixbnfc.io" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">nfc@allinonetoken.io</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="mailto:sale@bixbnfc.io" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">sale@allinonetoken.io</a>
                                    </li>
                                    <li className="mb-4 flex justify-center sm:justify-start">
                                        <a href="mailto:support@bixbnfc.io" className="transition-all text-gray hover:text-[#23F4F9] text-[0.75rem]">support@allinonetoken.io</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="text-gray text-[0.75rem] py-5 px-2 text-center lg:text-start">
                        Copyright {new Date().getFullYear()} ALLINONE
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;