import ScrollAnimation from 'react-animate-on-scroll';

export function Information() {
    return (
        <>
            <div style={{ backgroundImage: "url(/image/information.jpg)" }} className="bg-center bg-cover container-fluid flex items-center justify-center h-[328px]">
                <ScrollAnimation animateOnce={true} animateIn="animate__fadeIn">
                    <div className="text-xl md:text-3xl lg:text-5xl xl:text-6xl text-white text-center leading-tight" style={{lineHeight: 1.2}}>
                        For more information,
                        <br />
                        visit <a rel="noreferrer" className="underline" href="https://www.sunmi.com/en/v2-pro/" target={'_blank'}>SUNMI V2 Pro Official Website</a>
                    </div>
                </ScrollAnimation>
            </div>
        </>
    )
}