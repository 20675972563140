import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const QrCode = () => {
    return (
        <>
            <div className='container mx-auto mt-16'>
                <div className='flex sm:flex-row flex-col-reverse'>
                    <div className='flex grow sm:basis-1/2 basis-full items-center'>
                        <div>
                            <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInLeft">
                                <picture><img alt='qrCode' className='w-[500px]' src='/image/new/nfcQrMethod.png' /></picture>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className='flex flex-col grow basis-1/2 items-center justify-center px-5 lg:px-10'>
                        <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInRight">
                            <div className='lg:text-[3rem] text-[1.5rem] sm:text-start text-center'>
                                <span className='text-[#23F4F9]'>QR-Code</span><span className='text-white'> Method</span>
                            </div>
                            <div className='flex py-5 text-white text-left md:text-justify font-extralight lg:font-normal'>
                                QR code AIO payments are made via the customer’s smartphone when they scan the code on the merchant's device. Once scanned, they get taken to a payment page where they can enter their chosen payment details or use a mobile payment solution via the App to complete the transaction.                </div>
                            <div className='flex flex-row pt-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    If your device does not support NFC, you are free to use QR codes.
                                </div>
                            </div>
                            <div className='flex flex-row py-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    The QR code method is a fast and convenient way for payments.
                                </div>
                            </div>
                            <div className='flex flex-row text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    Even without purchasing an NFC card, you can use this method.
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </>
    )
}
export default QrCode;