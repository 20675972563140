import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const WorksWith = () => {
    return (
        <>
            <div className='container-fluid mx-auto mt-10 mb-0 py-5'>
                <div className='text-center justify-center'>
                    <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeIn">
                        <div className='lg:text-[2.125rem] text-[1.5rem] pt-5 text-white sm:mb-0 mb-2'>Accepted Tokens</div>
                    </ScrollAnimation>
                </div>
                <div className='flex flex-row'>
                    <div className='grow sm:basis-1/4 sm:block hidden'>
                        <div className='flex justify-start lg:w-auto w-[12.5rem]'>
                            <picture><img alt='hand' src='/image/hand.svg' /></picture>
                        </div>
                    </div>
                    <div className='flex grow sm:basis-2/4 basis-full justify-center' style={{transform: 'translateY(-20px)'}}>
                        <div className='flex flex-wrap items-center justify-center w-full'>
                            <div className='grid sm:grid-cols-7 grid-cols-3 gap-3 w-full'>
                                <div className="pt-5 mb-5">
                                    <div className="mb-3">
                                        <ScrollAnimation animateOnce={true} animateIn="animate__zoomIn">
                                            <picture ><img alt='Logo' style={{ margin: '0 auto', height: "35px" }} src='/image/tokens/btc.svg' /></picture>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className="pt-5 mb-5">
                                    <div className="mb-3">
                                        <ScrollAnimation animateOnce={true} delay={300} animateIn="animate__zoomIn">
                                            <picture><img alt='Logo' style={{ margin: '0 auto', height: "35px" }} src='/image/tokens/eth.svg' /></picture>
                                        </ScrollAnimation>
                                    </div>
                                </div>

                                <div className="pt-5 mb-5">
                                    <div className="mb-3">
                                        <ScrollAnimation animateOnce={true} delay={400} animateIn="animate__zoomIn">
                                            <picture><img alt='Logo' style={{ margin: '0 auto', height: "35px" }} src='/image/tokens/bnb.svg' /></picture>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className="pt-5 mb-5">
                                    <div className="mb-3">
                                        <ScrollAnimation animateOnce={true} delay={500} animateIn="animate__zoomIn">
                                            <picture><img alt='Logo' style={{ margin: '0 auto', height: "35px" }} src='/image/tokens/aio.png' /></picture>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className="pt-5 mb-5">
                                    <div className="mb-3">
                                        <ScrollAnimation animateOnce={true} delay={600} animateIn="animate__zoomIn">
                                            <picture><img alt='Logo' style={{ margin: '0 auto', height: "35px" }} src='/image/tetherLogo.svg' /></picture>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className="pt-5 mb-5">
                                    <div className="mb-3">
                                        <ScrollAnimation animateOnce={true} delay={700} animateIn="animate__zoomIn">
                                            <picture><img alt='Logo' style={{ margin: '0 auto', height: "35px" }} src='/image/tokens/trx.svg' /></picture>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className="pt-5 mb-5">
                                    <div className="mb-3">
                                        <ScrollAnimation animateOnce={true} delay={800} animateIn="animate__zoomIn">
                                            <picture><img alt='Logo' style={{ margin: '0 auto', height: "35px" }} src='/image/tokens/doge.svg' /></picture>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grow sm:basis-1/4 sm:block hidden'></div>
                </div>
            </div>
        </>
    )
}
export default WorksWith;