import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Stats = () => {
    return (
        <>
            <div className='container mx-auto mt-0 xl:mt-5 2xl:mt-0 mb-0 md:py-5 text-white'>
                <div className='flex sm:flex-row flex-col'>
                    <div className='flex flex-col items-center grow lg:basis-1/3 basis-full'>
                        <ScrollAnimation initiallyVisible={true} animateOnce={true} animateIn="animate__fadeIn">
                            <div className='sm:mb-0 mb-5'>
                                <div className='xl:text-[3rem] text-[2rem] flex sm:justify-start justify-center'>
                                    +6K
                                </div>
                                <div className='text-[1rem] flex sm:justify-start justify-center'>
                                    Daily Transaction
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className='flex items-end'>
                        <div className='sm:block hidden'>
                            <picture><img alt="line" src='/image/blueLine.svg' /></picture>
                        </div>
                    </div>
                    <div className='flex flex-col items-center grow lg:basis-1/3 basis-full mt-3 md:mt-0'>
                        <ScrollAnimation initiallyVisible={true} animateOnce={true} animateIn="animate__fadeIn">
                            <div className='sm:mb-0 mb-5'>
                                <div className='xl:text-[3rem] text-[2rem] flex sm:justify-start justify-center'>
                                    100%
                                </div>
                                <div className='text-[1rem] flex sm:justify-start justify-center'>
                                    Safety
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className='flex items-end'>
                        <div className='sm:block hidden'>
                            <picture><img alt="line" src='/image/blueLine.svg' /></picture>
                        </div>
                    </div>
                    <div className='flex flex-col items-center grow lg:basis-1/3 basis-full  mt-3 md:mt-0'>
                        <ScrollAnimation initiallyVisible={true} animateOnce={true} animateIn="animate__fadeIn">
                            <div>
                                <div className='xl:text-[3rem] text-[2rem] flex sm:justify-start justify-center'>
                                    500
                                </div>
                                <div className='text-[1rem] flex sm:justify-start justify-center'>
                                    monthly User growth
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Stats;