import './App.css';
import "animate.css/animate.min.css";
import { Routes, Route } from "react-router-dom"
import { Home } from './Home';
import { Product } from './Product';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Product />} />
      </Routes>
    </div>
  );
}

export default App;
