import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const NfcMethod = () => {
    return (
        <>
            <div className='container mx-auto mt-16'>
                <div className='flex sm:flex-row flex-col'>
                    <div className='flex flex-col grow basis-1/2  items-center justify-center px-5 lg:px-10'>
                        <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInLeft">
                            <div className='lg:text-[3rem] text-[1.5rem] sm:text-start text-center'>
                                <span className='text-[#23F4F9] mr-2'>NFC</span><span className='text-white'>Payment Via Smart Phone</span>
                            </div>
                            <div className='flex py-5 text-white text-left md:text-justify font-extralight lg:font-normal'>
                                At checkout, the merchant will simply prompt the customer to hold their phone near the NFC payment device, and the payment will be securely completed using NFC technology while it doesn't require having your NFC card with you.                </div>
                            <div className='flex flex-row pt-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    Accepting AIO NFC payments is easy and convenient via your smart phone.
                                </div>
                            </div>
                            <div className='flex flex-row py-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    Two NFC-enabled devices can establish a peer-to-peer connection and share data.
                                </div>
                            </div>
                            <div className='flex flex-row text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    An NFC device can be used like a contactless credit card only using an App.
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className='flex grow sm:basis-1/2 basis-full items-center justify-center'>
                        <div>
                            <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInRight">
                                <picture><img alt='check' className='w-[500px]' src='/image/new/nfcMethod.png' /></picture>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NfcMethod;