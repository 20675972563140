import Stats from './component/Stats';
import GetStart from './component/GetStart';
import PaymentMethods from './component/PaymentMethods';
import WorksWith from './component/WorksWith';
import Download from './component/Download';
import Footer from './component/Footer';
import Nav from './component/Nav';
import NfcCardMethod from './component/NfcCardMethod';
import NfcMethod from './component/Nfc';
import QrCode from './component/QrCode';
import Features from './component/Features';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

export function Home() {
    const location = useLocation();

    useEffect(() => {
        if (!location.hash) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }, [])

    return (
        <>
            <Nav />
            <GetStart />
            <Stats />
            <PaymentMethods />
            <NfcCardMethod />
            <NfcMethod />
            <QrCode />
            <WorksWith />
            <Features />
            <Download />
            <Footer />
        </>
    )
}