import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';


const Nav = () => {
  const [size, setSize] = useState(0);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    // The debounce function receives our function as a parameter
    const debounce = (fn) => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame;
      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame);
        }
        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params);
        });
      };
    };

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      setSize(window.scrollY)
      document.documentElement.dataset.scroll = window.scrollY;
    };

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    });

    // Update scroll position for first time
    storeScroll();
  });

  const data = [
    {
      item: "Home",
      url: "/",
      name: '',
      hash: false
    },
    {
      item: "Products",
      url: "/products",
      name: 'products',
      hash: false
    },
    {
      item: "Methods",
      url: "/#methods",
      name: "#",
      hash: true
    },
    {
      item: "Features",
      url: "/#features",
      name: "#",
      hash: true
    },
    {
      item: "Download",
      url: "/#download",
      name: "#",
      hash: true
    },
  ];

  return (
    <>
      <nav className={`px-2 sm:px-4 xl:px-20 lg:px-10 py-2.5 fixed w-full z-20 top-0 left-0 ${size > 99 ? 'bg-bgColor' : ''}`}>
        <div className="container-fluid flex flex-wrap items-center justify-between mx-auto">
          <div className="flex basis-1/2 md:basis-1/3">
            <a href="/" className="flex items-center">
              <img alt="logo aionfc" src="/image/aio-nfc-logo.png" className="h-14"/>
            </a>
          </div>
          <div className="flex md:basis-2/3 hidden sm:block">
            <div
              className="items-center justify-between flex w-auto md:order-1" id="navbar-sticky"
            >
              <div className="flex text-white md:text-[1rem] text-[0.75rem] md:p-4 mt-5 flex-row md:mt-0">
                {data.map((item, index) => {
                  if (item.hash) {
                    return (<NavHashLink smooth activeClassName={'font-bold md:mx-2 lg:mx-4 mx-1'} className={"md:mx-2 lg:mx-4 mx-1"} key={index} to={item.url}>{item.item}</NavHashLink>)
                  } else {
                    return (<NavLink className={splitLocation[1] === item.name ? "font-bold md:mx-2 lg:mx-4 mx-1 text-[#23F4F9]" : "md:mx-2 lg:mx-4 mx-1"} key={index} to={item.url} >{item.item}</NavLink>)
                  }
                })}
              </div>
            </div>
          </div>
          <div className="flex basis-1/2 sm:basis-1/3 items-center justify-end">
            <button onClick={() => { setOpen(!open) }} className="block sm:hidden p-2">
              <img src="/image/menu-2.svg" alt="" />
            </button>
            {
              open ?
                <div onClick={() => { setOpen(!open) }} className="overlay"></div>
                : null
            }
            <div className={`menu-mobile ${open ? `active` : null}`}>
              <div className="p-5">
                <button onClick={() => { setOpen(!open) }} className="block sm:hidden p-2">
                  <img src="/image/x.svg" alt="" />
                </button>
                <div className="flex text-white text-[1.2rem] md:p-4 mt-5 flex-col md:mt-0 text-center">
                  <div  style={{margin:'0 auto'}}>
                    <img className="mb-8" alt="logo aionfc" src="/image/aio-nfc-logo.png" width={130} />
                  </div>
                  {
                    data.map((item, index) => {
                      if (item.hash) {
                        return (<NavHashLink smooth activeClassName={'font-bold my-3'} className={"my-3"} key={index} to={item.url}>{item.item}</NavHashLink>)
                      } else {
                        return (<NavLink className={splitLocation[1] === item.name ? "font-bold my-3" : "my-3"} key={index} to={item.url} >{item.item}</NavLink>)
                      }
                    })
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Nav;
