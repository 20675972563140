import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const Download = () => {
  return (
    <>
      <div className='container mx-auto mt-0 mb-0 overflow-hidden height-[650px]' id="download">
        <div className='flex justify-center relative py-32 h-full'>
          <img className='w-full absolute h-full bottom-[-125px] left-0 md:left-[-15px]' style={{ margin: '0 auto' }} src='/image/bgdown2.png' />
          <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeIn">
            <div className='relative top-[-1rem] flex flex-col items-center justify-center'>
              <img className='lg:w-auto w-[12.5rem]' src="image/dollar.svg" />
              <div className='flex justify-center text-white top-[2rem] lg:text-[3.75rem] sm:text-[2rem] text-[1.8rem]'>
                Download Now
              </div>
              <div className='flex justify-center pt-8'>
                <a href='#' className="bg-white hover:bg-[#23F4F9] transition-colors rounded py-3 sm:px-7 px-2 flex justify-center text-center text-txtColor">
                  <div className='flex flex-row'>
                    <img alt='downloadIcons' src="/image/downloadIcons.svg" />
                    <div className='pl-2 sm:text-[1rem] text-[0.75rem]'>
                      Download for Android Phone
                    </div>
                  </div>
                </a>
              </div>
              <div className='flex justify-center pt-8'>
                <a href='#' className="bg-white rounded py-3 hover:bg-[#23F4F9] transition-colors px-4 flex justify-center text-center text-txtColor">
                  <div className='flex flex-row'>
                    <img alt='downloadIcons' src="/image/downloadIcons.svg" />
                    <div className='pl-2 sm:text-[1rem] text-[0.75rem]'>
                      Download for Android POS
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ScrollAnimation>
        </div>
        {/* <div className='flex justify-center relative top-[1rem] lg:left-[1.375rem] sm:left-[10.3rem] lg:w-auto sm:w-[28rem]'>
            <img className='sm:block hidden' src='/image/ellipse.svg'/>
          </div> */}
      </div>
    </>
  )
}
export default Download;