import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Introduction = () => {
    const [active, setActive] = useState(1);

    var data = [
        {
            id: 1,
            Type: "Blue Card",
            Expiry_Date: "1 Year",
            Transaction_Limit: "2000 AIO"
        },
        {
            id: 2,
            Type: "Gray Card",
            Expiry_Date: "2 Years",
            Transaction_Limit: "4000 AIO"
        },
        {
            id: 3,
            Type: "Green Card",
            Expiry_Date: "3 Years",
            Transaction_Limit: "6000 AIO"
        },
        {
            id: 4,
            Type: "Bussiness Black Card - (Lower Fees)",
            Expiry_Date: "5 Years",
            Transaction_Limit: "Unlimited"
        }
    ];

    const next = () => {
        if (active === 4) {
            setActive(1)
        } else {
            setActive(active + 1)
        }
    }
    const back = () => {
        if (active === 1) {
            setActive(4)
        } else {
            setActive(active - 1)
        }
    }



    return (
        <>
            <div className='container mx-auto mt-2 mb-0'>
                <div className='flex sm:flex-row flex-col'>
                    <div className='flex flex-col grow basis-1/2 items-center justify-center px-5 lg:px-10'>
                        <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInLeft">
                            <div className='lg:text-[3rem] text-[1.5rem] sm:text-start text-center'>
                                <span className='text-white'>AIO NFC Cards Introduction</span>
                            </div>
                            <div className='flex py-5 text-white md:text-justify text-center font-extralight lg:font-normal'>
                                AIO NFC Cards are provided in four different colors. Each color represents the specifications of that card:
                            </div>
                            <div className='flex flex-row pt-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    Type: {data.find(item => item.id === active).Type}
                                </div>
                            </div>
                            <div className='flex flex-row py-2 text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    Expiry Date: {data.find(item => item.id === active).Expiry_Date}
                                </div>
                            </div>
                            <div className='flex flex-row text-white'>
                                <picture><img alt='check' className='pr-4' src='/image/checkMark.svg' /></picture>
                                <div className='flex-1 font-extralight lg:font-normal'>
                                    Transaction Limit: {data.find(item => item.id === active).Transaction_Limit}
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className='flex grow sm:basis-1/2 basis-full pt-0 px-1 lg:px-5 xl:px-0 sm:pt-16 lg:pt-28 xl:pt-0 my-10 md:my-0 items-center justify-center'>
                        <div>
                            <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeInRight">
                                <div className='relative'>
                                    <button className='absolute right-0 top-[45%]' onClick={() => { next() }}><picture><img className='w-[30px] xl:w-full' alt='check' src={`/image/slider/right.svg`} /></picture></button>
                                    <picture><img alt='check' className='lg:w-full w-[18rem]' src={`/image/slider/BG.png`} /></picture>
                                    <div className='picture-center w-[47%] absolute '>
                                        <picture><img alt='check' className='lg:w-full w-[18rem]' src={`/image/slider/NFC-Card-${active}.png`} /></picture>
                                    </div>
                                    <button className='absolute top-[45%]' onClick={() => { back() }}><picture><img alt='check' className='w-[30px] xl:w-full' src={`/image/slider/left.svg`} /></picture></button>

                                </div>

                            </ScrollAnimation>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Introduction;