import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const PaymentMethods = () => {
    return (
        <>
            <div className='container-fluid mx-auto mb-0 mt-20' id='methods'>
                <div className='flex flex-col lg:flex-row'>
                    <div className=' basis-full lg:basis-2/12'></div>
                    <div className='flex lg:basis-8/12 basis-full'>
                        <div className='xl:text-5xl 2xl:text-6xl w-full sm:text-[2rem] text-[1.8rem] text-white flex justify-center text-center'>
                            <ScrollAnimation animateOnce={true} duration={2} animateIn="animate__fadeIn">
                                Payment Methods
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className='flex basis-full lg:basis-2/12 justify-end relative'>
                        <picture><img alt="dollarCircle" className='hidden lg:block sm:w-[12.5rem] w-auto lg:w-auto right-0 absolute top-[-100px]' src='/image/dollarCircle.svg' /></picture>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PaymentMethods;